import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"8","sm":"6"}},[_c(VCardTitle,{staticClass:"greeting-title d-flex flex-nowrap text-2xl"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("欢迎登录消息中心")]),_c('span',{staticClass:"text-no-wrap font-weight-bold mx-2"},[_vm._v(_vm._s(_vm.userName))]),_c('span',{staticClass:"text-no-wrap"},[_vm._v("!🎉")])]),_c(VCardText,[_c('span',[_vm._v("You have done ")]),_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v("72%")]),_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v(" 🤩 more sales today.")]),_c('span',[_vm._v(" Check your new raising badge in your profile.")])])],1),_c(VCol,{attrs:{"cols":"4","sm":"6"}},[_c('div',[_c(VImg,{staticClass:"gamification-tree-4",attrs:{"width":"120","src":require("@/assets/images/misc/tree-4.png")}}),_c(VImg,{staticClass:"gamification-john-pose-2",attrs:{"width":"121","src":require("@/assets/images/3d-characters/pose-2.png")}}),_c(VImg,{staticClass:"gamification-tree",attrs:{"width":"100","src":require("@/assets/images/misc/tree.png")}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }