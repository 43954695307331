<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
      class="align-self-end"
    >
      <ecommerce-congratulation-john></ecommerce-congratulation-john>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="2"
      class="align-self-end"
    >
      <statistics-card-vertical
        :color="transactionsOptions.color"
        :icon="transactionsOptions.icon"
        :statistics="dingCount"
        :stat-title="transactionsOptions.statTitle"
        :subtitle="transactionsOptions.subtitle"
      ></statistics-card-vertical>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="2"
      class="align-self-end"
    >
      <statistics-card-vertical
        :color="revenueOptions.color"
        :icon="revenueOptions.icon"
        :statistics="msgCount"
        :stat-title="revenueOptions.statTitle"
        :subtitle="revenueOptions.subtitle"
      ></statistics-card-vertical>
    </v-col>

    <v-col
      cols="12"
      md="8"
    >
      <ecommerce-total-profit></ecommerce-total-profit>
    </v-col>

    <v-col
      cols="12"
      md="4"
    >
      <v-row class="match-height">
        <v-col cols="12">
          <ecommerce-statistics-total-sales></ecommerce-statistics-total-sales>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-line-chart
            ref="bbb"
            :stat-title="revenueLineChart.statTitle"
            :statistics="revenueLineChart.statistics"
            :chart-series="weekMsg"
            :chart-color="$vuetify.theme.currentTheme.primary"
            chart-height="130"
          ></statistics-card-line-chart>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-radial-bar-chart
            :stat-title="salesRadialChart.statTitle"
            :statistics="salesRadialChart.statistics"
            :chart-series="salesRadialChart.series"
            :chart-color="$vuetify.theme.currentTheme.info"
          ></statistics-card-radial-bar-chart>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCheck, mdiChartTimelineVariant } from '@mdi/js'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import StatisticsCardLineChart from '@core/components/statistics-card/StatisticsCardLineChart.vue'
import StatisticsCardRadialBarChart from '@core/components/statistics-card/StatisticsCardRadialBarChart.vue'
import store from '@/store'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import numberFormat from '@core/libs/numberFormat'
import getNearly7Day from '@core/libs/near7Day'
import storeModule from './ecommerceStoreModule'

// demos
import EcommerceCongratulationJohn from './EcommerceCongratulationJohn.vue'
import EcommerceTotalProfit from './EcommerceTotalProfit.vue'
import EcommerceStatisticsTotalSales from './EcommerceStatisticsTotalSales.vue'

export default {
  components: {
    StatisticsCardVertical,
    StatisticsCardLineChart,
    StatisticsCardRadialBarChart,
    EcommerceCongratulationJohn,
    EcommerceTotalProfit,
    EcommerceStatisticsTotalSales,
  },
  filters: {
    numberFormat,
    getNearly7Day,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'ecommerce-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const transactionsOptions = {
      statTitle: '短信发送',
      icon: 'mdi-email-fast',
      color: 'info',
      subtitle: '今日发送量',
      statistics: '1.2k',
      change: '+12%',
    }
    const revenueOptions = {
      statTitle: '钉钉发送',
      icon: 'mdi-send-circle',
      color: 'success',
      subtitle: '今日发送量',
      statistics: '95.2k',
      change: '+12%',
    }

    const revenueLineChart = {
      statTitle: '一周内',
      statistics: '消息统计',
      series: [
        {
          data: [28, 58, 40, 68, 22, 45, 54],
        },
      ],
    }

    const dingCount = ref('')
    const msgCount = ref('')

    const weekMsg = ref([
      {
        data: [28, 58, 40, 68, 22, 45, 54],
      }])

    const salesRadialChart = {
      statTitle: 'Total Sales',
      statistics: '135k',
      series: [78],
    }

    const logisticsOptions = {
      statTitle: 'Logistics',
      icon: mdiChartTimelineVariant,
      color: 'error',
      subtitle: 'Revenue Increase',
      statistics: '44.1k',
      change: '+12%',
    }

    const reportsOptions = {
      statTitle: 'Reports',
      icon: mdiCheck,
      color: 'warning',
      subtitle: 'System Bugs',
      statistics: '268',
      change: '-8%',
    }

    const showCounts = () => {
      store.dispatch('ecommerce-list/fetchCountsLists')
        .then(response => {
          const { dingMessage, msgMessage } = response.data.data
          dingCount.value = dingMessage.toString()
          msgCount.value = msgMessage.toString()
        })
        .catch(error => {
          console.log(error)
        })
    }
    showCounts()

    const bbb = ref(null)

    onMounted(() => {
      const nearSevenDay = getNearly7Day().reverse()

      store.dispatch('ecommerce-list/fetchMsgWeekLists')
        .then(response => {
          const { data } = response.data
          weekMsg.value[0].data = data
          bbb.value.update({
            xaxis: {
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: true,
                style: {
                  fontSize: '11px',
                },
              },
              type: 'category',
              categories: nearSevenDay,
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    })

    return {
      transactionsOptions,
      revenueOptions,
      revenueLineChart,
      salesRadialChart,
      logisticsOptions,
      reportsOptions,
      weekMsg,
      dingCount,
      msgCount,
      bbb,
    }
  },
}
</script>
