// 给日期加0
function addDate0(time) {
  if (time.toString().length === 1) {
    // eslint-disable-next-line no-param-reassign
    time = `0${time.toString()}`
  }

  return time
}

export default function getNearly7Day() {
  const days = []
  const date = new Date()
  for (let i = 0; i <= 24 * 6; i += 24) {
    // 今天加上前6天
    const dateItem = new Date(date.getTime() - i * 60 * 60 * 1000) // 使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    // const y = dateItem.getFullYear() // 获取年份
    let m = dateItem.getMonth() + 1 // 获取月份js月份从0开始，需要+1
    let d = dateItem.getDate() // 获取日期
    m = addDate0(m) // 给为单数的月份补零
    d = addDate0(d) // 给为单数的日期补零
    const valueItem = `${d}` // 组合
    days.push(valueItem) // 添加至数组
  }

  return days
}
