<template>
  <v-card>
    <div class="d-flex justify-space-between">
      <div>
        <v-card-title>
          消息分布
        </v-card-title>
        <v-card-text class="text-no-wrap mt-4">
          <p class="text-xs mb-0">
            总发送量
          </p>
          <span class="text-xl font-weight-semibold text--primary me-1">{{ total }}</span>
          <!--          <v-icon-->
          <!--            size="20"-->
          <!--            color="success"-->
          <!--          >-->
          <!--            {{ icons.mdiChevronUp }}-->
          <!--          </v-icon>-->
          <!--          <span class="success&#45;&#45;text text-xs font-weight-semibold">15.6%</span>-->
        </v-card-text>
      </div>

      <vue-apex-charts
        id="chart-stats-total-sales"
        height="140"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import { mdiChevronUp } from '@mdi/js'
import { getVuetify } from '@core/utils'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import storeModule from './ecommerceStoreModule'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'ecommerce-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const $vuetify = getVuetify()

    const resolveValText = val => {
      if (val === 'series-1') return '钉钉消息'
      if (val === 'series-2') return '短信消息'
      if (val === 'series-3') return '其他消息'

      return '各消息占比'
    }

    // const chartSeries = [85, 20, 30]
    const chartOptions = {
      chart: {
        offsetY: 7,
        type: 'donut',
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        colors: ['#fff'],
        width: 8,
      },
      grid: {
        padding: {
          bottom: 10,
        },
      },
      colors: [
        $vuetify.theme.currentTheme.primary,
        '#E9EAEC',
        $vuetify.theme.currentTheme.warning,
        $vuetify.theme.currentTheme.error,
      ],
      markers: {
        size: 6,
        colors: 'transparent',
        strokeColors: 'transparent',
        strokeWidth: 4,
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 3 - 1,
            size: 6,
          },
        ],
        hover: {
          size: 7,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
                formatter(val) {
                  return `${resolveValText(val)}`
                },
              },
              value: {
                offsetY: -13,
                formatter(value) {
                  return `${value}`
                },
              },
              total: {
                show: true,
                label: '各消息占比',
                formatter() {
                  return ''
                },
              },
            },
          },
        },
      },
    }

    const total = ref(0)
    const chartSeries = ref([33, 210, 2000])

    const showPlatform = type => {
      store.dispatch('ecommerce-list/fetchPlatformLists', type)
        .then(response => {
          const {
            messageTotal, dingMessage, msgMessage, otherMessage,
          } = response.data.data
          total.value = messageTotal
          const arr = []
          arr.push(dingMessage, msgMessage, otherMessage)
          chartSeries.value = arr
        })
        .catch(error => {
          console.log(error)
        })
    }
    showPlatform()

    return {
      total,
      chartSeries,
      chartOptions,
      resolveValText,

      // icons
      icons: {
        mdiChevronUp,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-stats-total-sales {
  max-width: 150px;
  height: auto;
  .apexcharts-pie {
    .apexcharts-pie-series .apexcharts-pie-area {
      stroke-width: 10;
    }
  }
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
        font-size: 1.25rem;
      }
      &.apexcharts-datalabel-label {
        font-size: 0.75rem;
        margin-bottom: 2rem;
        transform: translateY(-7px);
      }
    }
  }
}

.v-application {
  &.theme--dark {
    #chart-stats-total-sales {
      path {
        stroke: #312d4b;
      }
    }
  }
}
</style>
