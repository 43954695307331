import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTotalLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/videoCounts/totalCount`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlatformLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dash/getMessageTotal`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStaffTotal() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dash/empCount`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountsLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dash/getDayTotal`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMsgWeekLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dash/getWeekTotal`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
