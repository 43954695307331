import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c(VAvatar,{staticClass:"elevation-3",attrs:{"color":_vm.color,"size":"38"}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VSpacer)],1),_c(VCardText,{staticClass:"text-no-wrap text--primary mt-3"},[_c('p',{staticClass:"font-weight-semibold text-sm mb-1"},[_vm._v(" "+_vm._s(_vm.statTitle)+" ")]),_c('div',{staticClass:"d-flex align-end flex-wrap"},[_c('span',{staticClass:"font-weight-semibold text-2xl me-1 mb-2"},[_vm._v(_vm._s(_vm.statistics))]),_c('span',{staticClass:"percentage text-xs mb-2",class:_vm.checkChange(_vm.change) ? 'success--text':'error--text'},[_vm._v(" "+_vm._s(_vm.change))])]),_c('p',{staticClass:"text-xs text--secondary mb-0"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }