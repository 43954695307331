<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="7"
        class="total-profit-chart-col"
      >
        <v-card-title class="pt-2">
          员工统计
        </v-card-title>

        <vue-apex-charts
          id="total-profit-chart"
          ref="charts"
          height="320"
          :options="chartOptions"
          :series="chartData"
        ></vue-apex-charts>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <v-card elevation="0">
          <!-- Title/Header -->
          <v-card-title class="align-start pt-0 flex-nowrap">
            <div>
              <p class="mb-0 font-weight-bold text-2xl">
                {{ Total }}
              </p>
              <small class="text--secondary text-xs text-no-wrap">员工总数</small>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n6 mt-n1"
            >
              <v-icon size="22">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-3 pt-5">
            <!-- List -->
            <v-list
              two-line
              subheader
            >
              <!-- List Item: Income -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg primary--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    mdi-account-check-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{ onJob }}
                  </v-list-item-title>
                  <v-list-item-subtitle>在职员工</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- List Item: Profit -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg success--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="success"
                  >
                    mdi-account-clock-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{ partTime }}
                  </v-list-item-title>
                  <v-list-item-subtitle>兼职员工</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- List Item: Expense -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  color="primary"
                  class="v-avatar-light-bg secondary--text justify-center"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="secondary"
                  >
                    mdi-account-cancel-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    {{ leave }}
                  </v-list-item-title>
                  <v-list-item-subtitle>离职员工</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- Action Button -->
            <v-btn
              block
              color="primary"
              :to="{name:'staff-list'}"
            >
              查看详情
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'
import storeModule from '@/views/dashboards/urgent/ecommerceStoreModule'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'ecommerce-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const $vuetify = getVuetify()

    const chartOptions = {
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.secondary,
      ],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: ['杭州小五', '晋城小五', '猿马科技', '泰安小五', '小五文化', '小五影视', '风禾尽起'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }

    const chartData = ref([
      {
        name: '在职员工',
        data: [29000, 22000, 25000, 19000, 30000, 21000, 35000],
      },
      {
        name: '离职员工',
        data: [0, 0, 0, 14000, 0, 11000, 12000],
      },
    ])
    const charts = ref(null)
    const Total = ref(0)
    const leave = ref(0)
    const onJob = ref(0)
    const partTime = ref(0)
    const arrName = ref([])

    const Sum = arr => {
      let sum = 0
      for (let i = 0; i < arr.length; i += 1) { // 遍历数组
        // eslint-disable-next-line no-unused-vars
        sum += arr[i] // 求和
      }

      return sum
    }

    const showStaff = () => {
      store.dispatch('ecommerce-list/fetchStaffTotal')
        .then(response => {
          const arr = response.data.data.empCountList
          const arrLeave = []
          const arrOnJob = []
          arr.forEach(item => {
            arrName.value.push(item.comName)
            arrLeave.push(item.empLeave)
            arrOnJob.push(item.empOnJob)
          })
          leave.value = Sum(arrLeave)
          onJob.value = Sum(arrOnJob)
          Total.value = response.data.data.empTotal
          partTime.value = response.data.data.empPart
          chartData.value[0].data = arrOnJob
          chartData.value[1].data = arrLeave
          chartOptions.xaxis.categories = arrName
          charts.value.updateOptions({
            xaxis: {
              categories: arrName.value,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    showStaff()

    return {
      chartOptions,
      chartData,
      Total,
      leave,
      onJob,
      partTime,
      Sum,
      charts,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
